export enum EPermissions {
  ROOT = 0,

  // Gestão da produção

  VISUALIZAR_CAMPO = 73,
  CADASTRAR_CAMPO = 70,
  EDITAR_CAMPO = 71,
  APAGAR_CAMPO = 72,

  VISUALIZAR_ESTACAO = 63,
  CADASTRAR_ESTACAO = 60,
  EDITAR_ESTACAO = 61,
  APAGAR_ESTACAO = 62,

  VISUALIZAR_POCO = 157,
  CADASTRAR_POCO = 150,
  EDITAR_POCO = 151,
  APAGAR_POCO = 152,

  VISUALIZAR_CAPTACAO = 273,
  CADASTRAR_CAPTACAO = 270,
  EDITAR_CAPTACAO = 271,
  APAGAR_CAPTACAO = 272,

  VISUALIZAR_INJECAO = 283,
  CADASTRAR_INJECAO = 280,
  EDITAR_INJECAO = 281,
  APAGAR_INJECAO = 282,

  VISUALIZAR_MEDICAO_FISCAL_LINHA = 123,
  CADASTRAR_MEDICAO_FISCAL_LINHA = 120,
  EDITAR_MEDICAO_FISCAL_LINHA = 121,
  APAGAR_MEDICAO_FISCAL_LINHA = 122,
  IMPORTAR_MEDICAO_FISCAL_LINHA = 125,
  VALIDAR_MEDICAO_FISCAL_LINHA = 167,
  NOTIFICAR_FALHA_MEDICAO_FISCAL_LINHA = 121,

  VISUALIZAR_MEDICAO_FISCAL_TANQUE = 163,
  CADASTRAR_MEDICAO_FISCAL_TANQUE = 160,
  EDITAR_MEDICAO_FISCAL_TANQUE = 161,
  APAGAR_MEDICAO_FISCAL_TANQUE = 162,
  REABRIR_CICLO_MEDICAO_FISCAL_TANQUE = 164,
  VALIDAR_CICLO = 168,

  VISUALIZAR_MEDICAO_AGUA = 323,
  CADASTRAR_MEDICAO_AGUA = 320,
  EDITAR_MEDICAO_AGUA = 321,
  APAGAR_MEDICAO_AGUA = 322,

  VISUALIZAR_BOLETIM_GAS_VENTILADO = 603,
  CADASTRAR_BOLETIM_GAS_VENTILADO = 600,
  EDITAR_BOLETIM_GAS_VENTILADO = 601,
  APAGAR_BOLETIM_GAS_VENTILADO = 602,

  CADASTRAR_MEDICOES_PONTO_ESTIMADO = 1020,
  EDITAR_MEDICOES_PONTO_ESTIMADO = 1021,
  APAGAR_MEDICOES_PONTO_ESTIMADO = 1022,
  VISUALIZAR_MEDICOES_PONTO_ESTIMADO = 1023,

  CADASTRAR_MEDICOES_PONTO_OPERACIONAL = 1030,
  EDITAR_MEDICOES_PONTO_OPERACIONAL = 1031,
  APAGAR_MEDICOES_PONTO_OPERACIONAL = 1032,
  VISUALIZAR_MEDICOES_PONTO_OPERACIONAL = 1033,

  VISUALIZAR_CONSUMO_IBU = 328,
  CADASTRAR_CONSUMO_PARA_MEDICAO_IBU = 325,
  EDITAR_CONSUMO_PARA_MEDICAO_IBU = 326,
  APAGAR_CONSUMO_PARA_MEDICAO_IBU = 327,

  VISUALIZAR_PARADA_DE_POCO = 254,
  CADASTRAR_PARADA_DE_POCO = 250,
  EDITAR_PARADA_DE_POCO = 251,
  APAGAR_PARADA_DE_POCO = 252,
  CADASTRAS_PARADA_DE_POCO_SEM_RESTRICAO = 253,

  VISUALIZAR_SONOLOG = 523,
  CADASTRAR_SONOLOG = 520,
  EDITAR_SONOLOG = 521,
  APAGAR_SONOLOG = 522,
  IMPORTAR_PLANILHA_SONOLOG = 524,

  VISUALIZAR_FALHA = 780,
  CADASTRAR_FALHA = 781,
  EDITAR_FALHA = 782,
  APAGAR_FALHA = 783,

  VISUALIZAR_TESTE_POCO = 144,
  CADASTRAR_TESTE_POCO = 140,
  EDITAR_TESTE_POCO = 141,
  APAGAR_TESTE_POCO = 142,
  VALIDAR_TESTE_POCO = 143,
  GERAR_RELATORIO_TESTE_POCO = 145,
  GERAR_RELATORIO_TESTE_POCO_FATOR_ENCOLHIMENTO = 145,

  VISUALIZAR_CONFIG_SUPERFICIE_POCO = 138,
  CADASTRAR_CONFIG_SUPERFICIE_POCO = 135,
  EDITAR_CONFIG_SUPERFICIE_POCO = 136,
  APAGAR_CONFIG_SUPERFICIE_POCO = 137,

  CADASTRAR_CLUSTER = 75,
  EDITAR_CLUSTER = 76,
  APAGAR_CLUSTER = 77,
  VISUALIZAR_CLUSTER = 78,

  CADASTRAR_NO = 784,
  EDITAR_NO = 785,
  APAGAR_NO = 786,
  VISUALIZAR_NO = 787,

  CADASTRAR_GRUPO_PERDA = 800,
  EDITAR_GRUPO_PERDA = 801,
  APAGAR_GRUPO_PERDA = 802,
  VISUALIZAR_GRUPO_PERDA = 803,

  CADASTRAR_SUBGRUPO_PERDA = 810,
  EDITAR_SUBGRUPO_PERDA = 811,
  APAGAR_SUBGRUPO_PERDA = 812,
  VISUALIZAR_SUBGRUPO_PERDA = 813,

  CADASTRAR_INSTALACAO_DESTINO_TRANSFERENCIA = 1007,
  EDITAR_INSTALACAO_DESTINO_TRANSFERENCIA = 1008,
  APAGAR_INSTALACAO_DESTINO_TRANSFERENCIA = 1009,
  VISUALIZAR_INSTALACAO_DESTINO_TRANSFERENCIA = 1010,

  // Catálogo de Oportunidades
  CADASTRAR_LOCACAO = 860,
  EDITAR_LOCACAO = 861,
  APAGAR_LOCACAO = 862,
  VISUALIZAR_LOCACAO = 863,

  CADASTRAR_TIPO_PROJETO = 788,
  EDITAR_TIPO_PROJETO = 789,
  APAGAR_TIPO_PROJETO = 790,
  VISUALIZAR_TIPO_PROJETO = 791,

  CADASTRAR_PACOTE_PROJETO = 850,
  EDITAR_PACOTE_PROJETO = 851,
  APAGAR_PACOTE_PROJETO = 852,
  VISUALIZAR_PACOTE_PROJETO = 853,

  // Fechamento de Produção
  PRODUCAO_DIARIA_DE_PRODUCAO = 500,
  PRODUCAO_DIARIA_OPERACIONAL = 507,
  PROCESSAR_PRODUCAO_DIARIA_PRODUCAO = 501,
  INJECAO_DIARIA_DE_PRODUCAO = 503,
  GERAR_BMP = 502,
  CONSOLIDAR_BMP = 504,
  APAGAR_BMP = 505,
  JUSTIFICAR_BMP = 506,
  ACOMPANHAMENTO_POCO = 1000,
  OTIMIZACAO_ACOMPANHAMENTO_POCO = 1001,
  PROCESSAR_ACOMPANHAMENTO_POCO = 1002,

  // Relatórios
  GERAR_RELATORIO_TRANSFERENCIA_FISCAL_TANQUE = 165,
  GERAR_RELATORIO_MEDICAO_FISCAL = 166,
  GERAR_RELATORIO_MTBF = 255,
  GERAR_RELATORIO_PERDA = 256,

  // Gestão de Prazos
  GERAR_RELATORIO_GESTAO_PRAZO_TESTE_POCO = 865,
  GERAR_RELATORIO_GESTAO_PRAZO_INSTRUMENTO = 866,
  GERAR_RELATORIO_GESTAO_PRAZO_MEDICAO_TANQUE = 867,
  GERAR_RELATORIO_GESTAO_PRAZO_MEDICAO_LINHA = 868,
  GERAR_RELATORIO_GESTAO_PRAZO_PARADA = 869,
  GERAR_RELATORIO_GESTAO_PRAZO_ENVIO_TESTE_POCO = 870,
  GERAR_RELATORIO_GESTAO_PRAZO_BSW = 868,
  GERAR_RELATORIO_GESTAO_PRAZO_FERS = 868,
  GERAR_RELATORIO_GESTAO_PRAZO_GAS_VENTILADO = 871,

  // Gestão de Cartas
  VISUALIZAR_GESTAO_CARTA = 893,
  CADASTRAR_GESTAO_CARTA = 890,
  EDITAR_GESTAO_CARTA = 891,
  APAGAR_GESTAO_CARTA = 892,

  VISUALIZAR_SUPERINTENDENCIA = 897,
  CADASTRAR_SUPERINTENDENCIA = 894,
  EDITAR_SUPERINTENDENCIA = 895,
  APAGAR_SUPERINTENDENCIA = 896,

  // Reservatório
  VISUALIZAR_RESERVATORIO = 213,
  CADASTRAR_RESERVATORIO = 210,
  EDITAR_RESERVATORIO = 211,
  APAGAR_RESERVATORIO = 212,

  VISUALIZAR_RGO = 263,
  CADASTRAR_RGO = 260,
  EDITAR_RGO = 261,
  APAGAR_RGO = 262,

  VISUALIZAR_ZONA_PRODUTORA = 83,
  CADASTRAR_ZONA_PRODUTORA = 80,
  EDITAR_ZONA_PRODUTORA = 81,
  APAGAR_ZONA_PRODUTORA = 82,

  VINCULAR_ZONA_AO_POCO = 153,
  SALVAR_CARACTERISTICA_POCO = 154,
  ENCERRAR_CARACTERISTICA_POCO = 155,
  ENCERRAR_ZONA = 156,
  VISUALIZAR_ZONA = 158,

  CADASTRAR_PAP = 610,
  EDITAR_PAP = 611,
  APAGAR_PAP = 612,
  VISUALIZAR_PAP = 613,

  CADASTRAR_POTENCIAL = 615,
  EDITAR_POTENCIAL = 616,
  APAGAR_POTENCIAL = 617,
  VISUALIZAR_POTENCIAL = 618,

  // Equipamentos
  VISUALIZAR_EQUIPAMENTO = 133,
  CADASTRAR_EQUIPAMENTO = 130,
  EDITAR_EQUIPAMENTO = 131,
  APAGAR_EQUIPAMENTO = 132,

  CADASTRAR_CABECOTE = 750,
  EDITAR_CABECOTE = 751,
  APAGAR_CABECOTE = 752,
  VISUALIZAR_CABECOTE = 753,

  CADASTRAR_TRANSFORMADOR = 740,
  EDITAR_TRANSFORMADOR = 741,
  APAGAR_TRANSFORMADOR = 742,
  VISUALIZAR_TRANSFORMADOR = 743,

  CADASTRAR_UNIDADE_BOMBEIO = 730,
  EDITAR_UNIDADE_BOMBEIO = 731,
  APAGAR_UNIDADE_BOMBEIO = 732,
  VISUALIZAR_UNIDADE_BOMBEIO = 733,

  VISUALIZAR_TAG = 113,
  CADASTRAR_TAG = 110,
  EDITAR_TAG = 111,
  APAGAR_TAG = 112,

  VISUALIZAR_PONTO_MEDICAO = 93,
  CADASTRAR_PONTO_MEDICAO = 90,
  EDITAR_PONTO_MEDICAO = 91,
  APAGAR_PONTO_MEDICAO = 92,
  ALTERAR_INSTRUMENTO_PONTO_MEDICAO = 94,
  ALTERAR_ESTADO_PONTO_MEDICAO = 95,

  VISUALIZAR_INSTRUMENTO = 223,
  CADASTRAR_INSTRUMENTO = 220,
  EDITAR_INSTRUMENTO = 221,
  APAGAR_INSTRUMENTO = 222,
  GERENCIAR_CALIBRACAO_INSTRUMENTO = 224,

  VISUALIZAR_ARQUEACAO = 233,
  CADASTRAR_ARQUEACAO = 230,
  EDITAR_ARQUEACAO = 231,
  APAGAR_ARQUEACAO = 232,

  VISUALIZAR_INTERPOLACAO = 243,
  CADASTRAR_INTERPOLACAO = 240,
  EDITAR_INTERPOLACAO = 241,
  APAGAR_INTERPOLACAO = 242,

  CADASTRAR_FABRICANTE = 701,
  EDITAR_FABRICANTE = 702,
  APAGAR_FABRICANTE = 703,
  VISUALIZAR_FABRICANTE = 704,

  VISUALIZAR_LACRE = 773,
  EDITAR_LACRE = 771,
  APAGAR_LACRE = 772,
  CADASTRAR_LACRE = 770,
  REMOVER_LACRE = 774,

  VISUALIZAR_LOTE = 763,
  EDITAR_LOTE = 761,
  APAGAR_LOTE = 762,
  CADASTRAR_LOTE = 760,

  CADASTRAR_FORNECEDOR = 755,
  EDITAR_FORNECEDOR = 756,
  APAGAR_FORNECEDOR = 757,
  VISUALIZAR_FORNECEDOR = 758,

  // Laboratório
  VISUALIZAR_AMOSTRA = 308,
  CADASTRAR_AMOSTRA = 305,
  EDITAR_AMOSTRA = 306,
  APAGAR_AMOSTRA = 307,

  VISUALIZAR_BSW = 203,
  CADASTRAR_BSW = 200,
  EDITAR_BSW = 201,
  APAGAR_BSW = 202,

  VISUALIZAR_CROMATOGRAFIA = 208,
  CADASTRAR_CROMATOGRAFIA = 205,
  EDITAR_CROMATOGRAFIA = 206,
  APAGAR_CROMATOGRAFIA = 207,

  VISUALIZAR_DENSIDADE = 173,
  CADASTRAR_DENSIDADE = 170,
  EDITAR_DENSIDADE = 171,
  APAGAR_DENSIDADE = 172,

  VISUALIZAR_FERS = 183,
  CADASTRAR_FERS = 180,
  EDITAR_FERS = 181,
  APAGAR_FERS = 182,

  VISUALIZAR_H2S = 193,
  CADASTRAR_H2S = 190,
  EDITAR_H2S = 191,
  APAGAR_H2S = 192,

  VISUALIZAR_SALINIDADE = 198,
  CADASTRAR_SALINIDADE = 195,
  EDITAR_SALINIDADE = 196,
  APAGAR_SALINIDADE = 197,

  // Administração

  VISUALIZAR_CARGO = 34,
  CADASTRAR_CARGO = 31,
  EDITAR_CARGO = 32,
  APAGAR_CARGO = 33,

  VISUALIZAR_COLABORADOR = 44,
  CADASTRAR_COLABORADOR = 41,
  EDITAR_COLABORADOR = 42,
  APAGAR_COLABORADOR = 43,

  VISUALIZAR_EMPRESA = 24,
  CADASTRAR_EMPRESA = 21,
  EDITAR_EMPRESA = 22,
  APAGAR_EMPRESA = 23,

  VISUALIZAR_PERFIL = 14,
  CADASTRAR_PERFIL = 11,
  EDITAR_PERFIL = 12,
  APAGAR_PERFIL = 13,

  GERAR_BRA = 309,
  CONSOLIDAR_BRA = 310,

  VISUALIZAR_USUARIO = 4,
  CADASTRAR_USUARIO = 1,
  EDITAR_USUARIO = 2,
  APAGAR_USUARIO = 3
}
